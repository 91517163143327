import { currentYear, globalInputKeys } from './constants'
import { isNumVal } from './number'
import { TAllTestKey, awardTestKeysHasShuttleRunOnly } from './test-const'
import { TVal } from './types/test-res'

export interface ITestValue {
  grip: string | number
  sitUps: string | number
  bending: string | number
  enduranceRun?: string | number
  sprintRun: string | number
  sideJump: string | number
  shuttleRun: string | number
  standingJump: string | number
  handballThrow: string | number
}

export const isTestResultNotExist = (
  result:
    | undefined
    | null
    | {
        value?: number | null
        isNotMeasurable?: boolean | null
        points?: number | null
      },
): boolean =>
  !result ||
  result.isNotMeasurable ||
  result.value === null ||
  result.value === undefined ||
  (result.value === 0 && result.points === 0)

export const showTestResultValue = (
  result:
    | undefined
    | null
    | {
        value?: number | null
        isNotMeasurable?: boolean | null
        points?: number | null
      },
) => {
  if (
    result == null ||
    result.isNotMeasurable ||
    result.value === null ||
    result.value === undefined ||
    (result.value === 0 && result.points === 0)
  ) {
    return ''
  }

  return result.points
}

const checkIfInputtedTestValue = (testRes: {
  value: number
  isNotMeasurable?: boolean | null
}) => testRes?.isNotMeasurable === true || !!testRes?.value

export const checkIfIsAllTestResInputted = (
  testResults: Record<string, any>[] | undefined,
  hasEnduranceRun: boolean,
  inputKeys: typeof globalInputKeys | typeof awardTestKeysHasShuttleRunOnly,
  testYear: number,
) => {
  if (!testResults || testResults.length === 0) return false

  const yearTestRes = testResults.find((item) => item.testYear === testYear)
  if (!yearTestRes) return false

  if (
    hasEnduranceRun &&
    !(
      checkIfInputtedTestValue(yearTestRes.enduranceRun) ||
      checkIfInputtedTestValue(yearTestRes.shuttleRun)
    )
  ) {
    return false
  }

  let isInputted = false

  isInputted = inputKeys.every((key: TAllTestKey) => {
    const testRes = yearTestRes[key]
    if (hasEnduranceRun && (key === 'enduranceRun' || key === 'shuttleRun')) {
      // ignore this case
      return true
    }

    if (!testRes) return false

    if (
      key === 'sitUps' ||
      key === 'grip' ||
      key === 'shuttleRun' ||
      key === 'handballThrow'
    ) {
      return (
        testRes.isNotMeasurable === true ||
        (testRes.value !== undefined &&
          testRes.value !== null &&
          !(testRes.value === 0 && testRes.points === 0))
      )
    }

    return checkIfInputtedTestValue(testRes)
  })

  return (
    isInputted &&
    yearTestRes.sizeTest &&
    !!yearTestRes.sizeTest.height &&
    !!yearTestRes.sizeTest.weight
  )
}

/**
 *
 *   GRIP_STRENGTH: '握力',
 *   SIT_UPS: '上体起こし',
 *   BENDING: '長座体前屈',
 *   SHUTTLE_RUN: '20mシャトルラン',
 *   ENDURANCE_RUN: '持久走',
 *   SIDE_JUMP: '反復横とび',
 *   SPRINT_RUN: '50m走',
 *   STANDING_JUMP: '立ち幅とび',
 *   HANDBALL_THROW: 'ボール投げ',
 */
export const testInputConfDefined = {
  height: 1,
  weight: 2,
  grip: 3,
  sitUps: 4,
  bending: 5,
  sideJump: 6,
  shuttleRunOrEnduranceRun: 7,
  sprintRun: 8,
  standingJump: 9,
  handballThrow: 10,
} as const

export const countConfDefaultValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const isIncludeTestTypeToCount = (
  testConf: number[],
  testTypeStr:
    | keyof typeof testInputConfDefined
    | 'shuttleRun'
    | 'enduranceRun',
) => {
  if (testTypeStr === 'shuttleRun' || testTypeStr === 'enduranceRun') {
    return testConf.includes(testInputConfDefined.shuttleRunOrEnduranceRun)
  }
  return testConf.includes(testInputConfDefined[testTypeStr])
}

export const checkIfIsAllTestResInputtedForStatistics = (
  testResults: Record<string, any>[] | undefined,
  hasEnduranceRun: boolean,
  inputKeys: typeof globalInputKeys | typeof awardTestKeysHasShuttleRunOnly,
  testConf: number[] | undefined | null,
) => {
  if (!testConf) {
    testConf = countConfDefaultValues
  }

  if (testConf.length === 0) return true
  if (!testResults || testResults.length === 0) return false

  const yearTestRes = testResults.find((item) => item.testYear === currentYear)
  if (!yearTestRes) return false

  if (
    isIncludeTestTypeToCount(testConf, 'shuttleRunOrEnduranceRun') &&
    hasEnduranceRun &&
    !(
      checkIfInputtedTestValue(yearTestRes.enduranceRun) ||
      checkIfInputtedTestValue(yearTestRes.shuttleRun)
    )
  ) {
    return false
  }

  let isInputted = false

  isInputted = inputKeys.every((key: TAllTestKey) => {
    const testRes = yearTestRes[key]
    if (hasEnduranceRun && (key === 'enduranceRun' || key === 'shuttleRun')) {
      // ignore this case
      return true
    }

    if (!isIncludeTestTypeToCount(testConf as number[], key)) {
      // do not count this case
      return true
    }

    if (!testRes) return false

    if (
      key === 'sitUps' ||
      key === 'grip' ||
      key === 'shuttleRun' ||
      key === 'handballThrow'
    ) {
      return (
        testRes.isNotMeasurable === true ||
        (testRes.value !== undefined &&
          testRes.value !== null &&
          !(testRes.value === 0 && testRes.points === 0))
      )
    }

    return checkIfInputtedTestValue(testRes)
  })

  const isCountHeight = isIncludeTestTypeToCount(testConf, 'height')
  const isCountWeight = isIncludeTestTypeToCount(testConf, 'weight')

  if (!isCountHeight && isCountWeight) {
    return isInputted && yearTestRes.sizeTest && !!yearTestRes.sizeTest.weight
  }

  if (isCountHeight && !isCountWeight) {
    return isInputted && yearTestRes.sizeTest && !!yearTestRes.sizeTest.height
  }

  if (!isCountHeight && !isCountWeight) {
    return isInputted
  }

  return (
    isInputted &&
    yearTestRes.sizeTest &&
    !!yearTestRes.sizeTest.height &&
    !!yearTestRes.sizeTest.weight
  )
}

export const filterNotNullTestResAndAddToArr = (
  submitData: Record<string, string | number | object | undefined>[],
  values: {
    schoolGrade: number
    schoolClass: number
    schoolAttendanceNumber: number
    height: TVal
    weight: TVal
    gripRight1: TVal
    gripRight2: TVal
    gripLeft1: TVal
    gripLeft2: TVal
    sitUps: TVal
    bending1: TVal
    bending2: TVal

    sideJump1: TVal
    sideJump2: TVal

    shuttleRun: TVal
    sprintRun: TVal

    standingJump1: TVal
    standingJump2: TVal

    handballThrow1: TVal
    handballThrow2: TVal

    runningTime: TVal
    runningTimeSeconds: TVal
  },
) => {
  const grip =
    isNumVal(values.gripRight1) ||
    isNumVal(values.gripRight2) ||
    isNumVal(values.gripLeft1) ||
    isNumVal(values.gripLeft2)
      ? {
          gripRight1: values.gripRight1,
          gripRight2: values.gripRight2,
          gripLeft1: values.gripLeft1,
          gripLeft2: values.gripLeft2,
        }
      : undefined

  const sitUps = isNumVal(values.sitUps)
    ? {
        sitUps: values.sitUps,
      }
    : undefined

  const bending =
    isNumVal(values.bending1) || isNumVal(values.bending2)
      ? {
          bending1: values.bending1,
          bending2: values.bending2,
        }
      : undefined

  const sideJump =
    isNumVal(values.sideJump1) || isNumVal(values.sideJump2)
      ? {
          sideJump1: values.sideJump1,
          sideJump2: values.sideJump2,
        }
      : undefined

  const enduranceRun =
    isNumVal(values.runningTime) || isNumVal(values.runningTimeSeconds)
      ? {
          // TODO: runningTime should be time in mm:ss format
          runningTime: values.runningTime?.toString(),
          // TODO: runningTimeSeconds should be the result of adding runningTime minutes and seconds
          runningTimeSeconds: values.runningTimeSeconds,
        }
      : undefined

  const shuttleRun = isNumVal(values.shuttleRun)
    ? {
        shuttleRunCount: values.shuttleRun,
      }
    : undefined

  const sprintRun = isNumVal(values.sprintRun)
    ? {
        sprintRunSeconds: values.sprintRun,
      }
    : undefined

  const standingJump =
    isNumVal(values.standingJump1) || isNumVal(values.standingJump2)
      ? {
          standingJump1: values.standingJump1,
          standingJump2: values.standingJump2,
        }
      : undefined

  const handballThrow =
    isNumVal(values.handballThrow1) || isNumVal(values.handballThrow2)
      ? {
          handballThrow1: values.handballThrow1,
          handballThrow2: values.handballThrow2,
        }
      : undefined

  const sizeTest = {
    weight: isNumVal(values.weight) ? values.weight : undefined,
    height: isNumVal(values.height) ? values.height : undefined,
  }

  submitData.push({
    schoolGrade: values.schoolGrade,
    schoolClass: values.schoolClass,
    schoolAttendanceNumber: values.schoolAttendanceNumber,
    grip,
    sitUps,
    bending,
    sideJump,
    //　(!hasEnduranceTest ? 'enduranceRun' : 'shuttleRun'))

    enduranceRun,
    shuttleRun,
    sprintRun,
    standingJump,
    handballThrow,
    sizeTest,
  })
}
