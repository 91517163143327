import create from 'zustand'

export interface IFirstTime {
  isFirstTimeLogin: boolean
}

interface IFirstTimeStore extends IFirstTime {
  setIsFirstTimeLogin: (isOnline: boolean) => void
}

export const useFirstTimeStore = create<IFirstTimeStore>()((set) => ({
  isFirstTimeLogin: false,
  setIsFirstTimeLogin: (isFirstTimeLogin: boolean) => set({ isFirstTimeLogin }),
}))
