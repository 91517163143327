import './MeasurableModal.css'

import { Modal } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const MeasurableModal: FC<{
  isModalOpen: boolean | object
  setIsModalOpen: (isModalOpen: boolean) => void
  handleOk: () => void
}> = ({ isModalOpen, setIsModalOpen, handleOk }) => {
  const { t } = useTranslation()

  return (
    <Modal
      visible={!!isModalOpen}
      onOk={handleOk}
      onCancel={() => setIsModalOpen(false)}
      centered
      footer={null}
      closeIcon={null}
      closable={false}
    >
      <div className="p-4 ml-2 text-black text-base">
        {t('「今年度測定なし」の設定を解除して値を入力しますか？')}
      </div>

      <div className="flex justify-center my-6 space-x-6">
        <button
          type="button"
          onClick={handleOk}
          className="px-4 py-2 w-28 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
        >
          {t('入力')}
        </button>

        <button
          type="button"
          onClick={() => setIsModalOpen(false)}
          className="px-4 py-2 w-28 text-gray-700 rounded-lg hover:bg-gray-300 border-black border-1px border-solid"
        >
          {t('キャンセル')}
        </button>
      </div>
    </Modal>
  )
}

export default MeasurableModal
