import { ReactComponentElement } from 'react'
import create from 'zustand'

type TTitleView = ReactComponentElement<any> | string

export interface IMenuInfo {
  menuId: number
  titleView: TTitleView
}

interface IMenuStore extends IMenuInfo {
  setMenu: (menuId: number, titleView: TTitleView) => void
}

export const useMenuStore = create<IMenuStore>()((set) => ({
  menuId: 1,
  titleView: '',
  setMenu: (menuId: number, titleView: TTitleView) =>
    set({ menuId, titleView }),
}))
