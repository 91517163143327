import './test-input-page.css'

import {
  Button,
  Checkbox,
  Col,
  Form,
  Grid,
  Input,
  InputNumber,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Table,
  message,
} from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useStudentsLazyQuery } from '@alpha/core'
import {
  CheckCircleOutlined,
  CloseOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { ColumnsType, SortOrder } from 'antd/lib/table/interface'
import { useAlphaStore } from '~/context'
import { useIsOnlineStore } from '~/context/online'
import { useAuthApiGet } from '~/hooks/hooks'
import { useLoadingPercent } from '~/hooks/useLoadingPercent'
import {
  ageFromSchool,
  allTestKeys,
  checkIfIsValidRank,
  reCalcTestResult,
  schoolHasEnduranceTest,
} from '~/utils'
import Api from '~/utils/api'
import { calculateBmi, calculateBmiByExistWeightHeight } from '~/utils/bmi'
import {
  currentYear,
  pageSize as defaultPageSize,
  globalInputKeys,
} from '~/utils/constants'
import isJapanese from '~/utils/isJapanese'
import {
  TAllTestKey,
  TAllTestKeyHeightWeight,
  awardTestKeysHasShuttleRunOnly,
} from '~/utils/test-const'
import {
  checkIfIsAllTestResInputted,
  checkIfIsAllTestResInputtedForStatistics,
} from '~/utils/test-result'
import { parseDisplayValueToValidateNumber } from '~/utils/validations'
import { TStudent } from '../../utils/types/api/res'
import { Dashboard } from '../layout/Dashboard'
import CircularProgressLoading from '../shared/CircularProgressLoading'
import { incorrectValue } from '../shared/IncorrectValue'
import MeasurableModal from './test-input/components/MeasurableModal'
import TestResInput from './test-input/components/TestResInput'
import {
  InputConfig,
  getInputListOfTestRes,
  getInputRulesForTestRes,
  getNoEnduranceRunInputList,
  heightRules,
  listInputEdit,
  // listInputEdit,
  weightRules,
} from './test-input/constants'
import {
  checkChangeTestKey,
  getTestNameByTestKey,
} from './test-input/utils/change'
import { convertValueForReqApi, setFormAll } from './test-input/utils/form'

const { Option } = Select
const { useBreakpoint } = Grid

/**
 * 体力テスト結果の入力ページ
 *
 * Path: /test
 */
const TestInputPage = () => {
  const { school } = useAlphaStore()
  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'
  const { t, i18n } = useTranslation()

  const isUsingJp = isJapanese(i18n)

  const [schoolGrade, setSchoolGrade] = useState(0)
  const [schoolClass, setSchoolClass] = useState(0)
  /**
   * Using at table.
   */
  const [filteredStudents, setFilteredStudents] = useState<TStudent[]>([])
  const studentsRef = useRef<TStudent[]>([])
  const [isNotMeasurable, setIsNotMeasurable] = useState<
    Record<string, boolean>
  >({})
  const [filterGender, setFilterGender] = useState('')
  const [selectedStudent, setSelectedStudent] = useState<null | TStudent>(null)
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [reloading, setReloading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [idModal, setIdModal] = useState('')
  const [isReadyToCallGetStudents, setIsReadyToCallGetStudents] =
    useState(false)

  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(defaultPageSize)

  const [measurableModalObj, setMeasurableModalObj] = useState<
    boolean | { studentId: string; keyId: number }
  >(false)

  const [form] = Form.useForm()
  const submittedRef = useRef<HTMLDivElement>(null)
  const submittingRef = useRef<HTMLDivElement>(null)

  const [getStudents, { data: studentData, loading: loadingStudent }] =
    useStudentsLazyQuery({
      fetchPolicy: 'network-only',
    })

  const { data: countConf, loading: countLoading } = useAuthApiGet<
    number[] | null
  >('/alpha/v1/school/test-count-conf')

  const loadingPercent = useLoadingPercent(160)

  const { isOnline } = useIsOnlineStore()

  // loadingStudents
  useEffect(() => {
    if (!school || !isReadyToCallGetStudents) return

    getStudents({
      variables: {
        input: {
          schoolId: school._id,
          testYear: currentYear,
          schoolClass,
          schoolGrade,
        },
      },
    })
  }, [school, getStudents, schoolGrade, schoolClass, isReadyToCallGetStudents])

  // setFilteredStudents
  const changedCounterRef = useRef(0)

  useEffect(() => {
    if (!school) return

    let _filteredStudents: TStudent[]

    if (isOnline) {
      if (!studentData?.students?.data) {
        return
      }

      setTotal(studentData.students.count ?? 0)
      _filteredStudents = studentData.students.data as TStudent[]
    } else {
      if (!studentData?.students?.data) {
        studentsRef.current = []
        setFilteredStudents([])
        setTotal(0)
        return
      }

      _filteredStudents = studentData.students.data.filter(
        (s) =>
          s.attributes.schoolClass === schoolClass &&
          s.attributes.schoolGrade === schoolGrade,
      ) as TStudent[]
    }

    if (filterGender !== '') {
      _filteredStudents = _filteredStudents.filter(
        (student) => student.attributes.gender === filterGender,
      )
    }

    const schoolCategoryCode = school.attributes.schoolCategoryCode

    _filteredStudents.forEach((student) => {
      if (!student?.attributes?.testResults) return

      const testResults = student.attributes.testResults

      const trIdx = testResults.findIndex((d) => d.testYear === currentYear)

      if (trIdx >= 0) {
        const age = ageFromSchool(
          schoolCategoryCode,
          student.attributes.schoolGrade as number,
        )

        const gender = student.attributes.gender

        if (gender) {
          const testRes = testResults[trIdx]

          // @ts-ignore
          student.attributes.testResults[trIdx] = reCalcTestResult(
            testRes,
            age,
            gender,
            hasEnduranceRun,
          )
        }
      }
    })

    setFilteredStudents(_filteredStudents)
    studentsRef.current = _filteredStudents
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    school,
    studentData?.students?.data,
    studentData?.students?.count,
    filterGender,
    isOnline,
    schoolClass,
    schoolGrade,
  ])

  const onChangeGrade = useCallback((val: number) => {
    setSchoolGrade(val)
  }, [])

  const onChangeClass = useCallback((val: number) => {
    setSchoolClass(val)
  }, [])

  const onChangeGender = useCallback((val: string) => {
    setFilterGender(val)
  }, [])

  // useEffect(() => {
  //   const handler = (event: BeforeUnloadEvent) => {
  //     event.preventDefault()
  //     event.returnValue = ''
  //   }

  //   if (formState !== 'unchanged') {
  //     window.addEventListener('beforeunload', handler)
  //     return () => {
  //       window.removeEventListener('beforeunload', handler)
  //     }
  //   }
  //   return () => {}
  // }, [formState])

  const hasEnduranceRun = schoolHasEnduranceTest(school?.attributes)

  const inputKeys = hasEnduranceRun
    ? globalInputKeys
    : awardTestKeysHasShuttleRunOnly

  const getInputList = () => {
    return hasEnduranceRun
      ? getInputListOfTestRes(t)
      : getNoEnduranceRunInputList(t)
  }

  const renderTest = (v, test) => {
    const defVal = incorrectValue
    if (!v || v.length === 0) return defVal
    const tr = v.find((d) => d.testYear === currentYear)
    if (!tr || tr.length === 0) return defVal

    switch (test) {
      case 'bending':
      case 'standingJump':
      case 'handballThrow': {
        if (!tr[test] || tr[test].length === 0) return defVal
        const value = tr[test].isNotMeasurable === true ? '無' : tr[test].value
        if (value === null || (!value && test !== 'sitUps')) return defVal
        return <div>{value}</div>
      }
      case 'grip':
      case 'sitUps':
      case 'sprintRun':
      case 'sideJump':
      case 'shuttleRun': {
        if (!tr[test] || tr[test].length === 0) return defVal
        const value = tr[test].isNotMeasurable === true ? '無' : tr[test].value
        if (value === null || (!value && test !== 'sitUps')) return defVal
        return <div>{value}</div>
      }
      // this is wrong (originally at least). runningTime is not a number. is the timeLabel (mm:ss)
      // runningTimeSeconds is the sum of minutes and seconds.
      case 'enduranceRun': {
        if (!tr[test] || tr[test].length === 0) return defVal
        if (tr.enduranceRun?.isNotMeasurable === true) return '無'
        const value =
          tr[test].runningTime && tr[test].runningTimeSeconds
            ? `${tr.enduranceRun?.runningTime}'${tr.enduranceRun?.runningTimeSeconds}''`
            : defVal
        if (!value) return defVal
        return <div>{value}</div>
      }
      case 'height':
      case 'weight': {
        const value = tr.sizeTest?.[test]
        if (!value || value.length === 0) return defVal
        return <div>{value}</div>
      }
      case 'bmi': {
        let value = ''
        if (tr?.sizeTest?.weight && tr.sizeTest.height) {
          value = calculateBmiByExistWeightHeight(tr.sizeTest)

          if (!value || value.length === 0) return defVal
        } else {
          return defVal
        }
        return <div>{Number(value)}</div>
      }
    }
  }

  const getTestResults = (student) => {
    return student.attributes.testResults?.find(
      (d) => d.testYear === currentYear,
    )
  }

  const sortTestResults = (a, b, key) => {
    let element1: undefined | number | string
    let element2: undefined | number | string

    switch (key) {
      case 'grip':
      case 'sitUps':
      case 'bending':
      case 'sprintRun':
      case 'sideJump':
      case 'standingJump':
      case 'shuttleRun':
      case 'handballThrow':
      case 'enduranceRun': {
        const a_tr = getTestResults(a)
        const b_tr = getTestResults(b)

        element1 = a_tr?.[key]?.value
        element2 = b_tr?.[key]?.value
        break
      }
      case 'rank':
      case 'points': {
        const a_tr = getTestResults(a)
        const b_tr = getTestResults(b)

        element1 = a_tr?.[key]
        element2 = b_tr?.[key]
        break
      }
      case 'height':
      case 'weight': {
        const a_tr = getTestResults(a)
        const b_tr = getTestResults(b)

        element1 = a_tr?.sizeTest?.[key]
        element2 = b_tr?.sizeTest?.[key]
        break
      }
      case 'bmi': {
        const a_tr = getTestResults(a)
        const b_tr = getTestResults(b)

        element1 = calculateBmi(a_tr?.sizeTest)
        element2 = calculateBmi(b_tr?.sizeTest)
        break
      }
      case 'name': {
        element1 = `${a?.familyName ?? ''}${a?.givenName ?? ''}`
        element2 = `${b?.familyName ?? ''}${b?.givenName ?? ''}`
        break
      }
      default: {
        element1 = a?.[key]
        element2 = b?.[key]
      }
    }

    if (element1 == null && element2 == null) return 1
    if (element1 == null) return -1
    if (element2 == null) return 1
    return typeof element1 === 'number'
      ? element1 - (element2 as number)
      : element1.localeCompare(element2 as string)
  }

  let tableColumns = [
    {
      title: t('登録状況'),
      dataIndex: ['attributes', 'testResults'],
      key: 'status',
      fixed: 'left',
      width: 85,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        const status1 = checkIfIsAllTestResInputted(
          a?.attributes?.testResults,
          hasEnduranceRun,
          inputKeys,
          currentYear,
        )
        const status2 = checkIfIsAllTestResInputted(
          b?.attributes?.testResults,
          hasEnduranceRun,
          inputKeys,
          currentYear,
        )

        return status1 === status2 ? 0 : !status1 ? -1 : 1
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => {
        const isAllInputted = checkIfIsAllTestResInputtedForStatistics(
          v,
          hasEnduranceRun,
          inputKeys,
          countConf,
        )

        return (
          <div
            className="flex justify-center items-center"
            style={{ height: 22 }}
          >
            <div
              className="flex justify-center items-center h-4 w-4 rounded-3px text-xxs font-bold text-white"
              style={{ backgroundColor: isAllInputted ? '#00944D' : '#CA4141' }}
            >
              {isAllInputted ? '済' : '未'}
            </div>
          </div>
        )
      },
    },

    {
      title: '',
      fixed: 'left',
      dataIndex: ['attributes'],
      key: 'actions',
      width: isUsingJp ? 58 : 74,
      render: (v, r) => {
        return (
          <div
            className={
              !v.testResults || v.testResults.length === 0
                ? 'bg-gray-160 -m-2 px-2'
                : ''
            }
          >
            <Button
              type="primary"
              className="ant-btn-xs text-10px test-result-details-btn"
              onClick={(e) => {
                e.preventDefault()
                if (!v.familyName) return

                editModal(r)
                setSelectedStudent(r)
                const tr = r.attributes.testResults.find(
                  (d) => d.testYear === currentYear,
                )

                const isNotMeasurable = {}
                getInputListOfTestRes(t).forEach((input) => {
                  isNotMeasurable[input.key] = tr?.[input.key]?.isNotMeasurable
                })
                setIsNotMeasurable(isNotMeasurable)
                setShowDetailModal(true)
                return false
              }}
              disabled={false}
            >
              {t('詳細')}
            </Button>
          </div>
        )
      },
    },
    {
      title: t('学年'),
      dataIndex: ['attributes', 'schoolGrade'],
      key: 'schoolGrade',
      fixed: 'left',
      width: isUsingJp ? 40 : 78,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolGrade')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return <div>{v}</div>
      },
    },
    {
      title: t('組'),
      dataIndex: ['attributes', 'schoolClass'],
      key: 'schoolClass',
      fixed: 'left',
      width: isUsingJp ? 40 : 78,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolClass')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return <div>{v}</div>
      },
    },
    {
      title: t('番'),
      dataIndex: ['attributes', 'schoolAttendanceNumber'],
      key: 'schoolAttendanceNumber',
      fixed: 'left',
      width: isUsingJp ? 40 : 92,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolAttendanceNumber')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return <div>{v}</div>
      },
    },
    {
      title: t('性別'),
      dataIndex: ['attributes', 'gender'],
      key: 'gender',
      fixed: 'left',
      width: 60,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        const gender1 = a?.attributes?.gender
        const gender2 = b?.attributes?.gender

        if (!gender1 && !gender2) return 1
        if (!gender1) return -1
        if (!gender2) return 1
        return gender1.localeCompare(gender2)
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => <div>{v ? (v === 'MALE' ? t('男') : t('女')) : '-'}</div>,
    },
    {
      title: t('名前'),
      dataIndex: ['attributes'],
      key: 'name',
      fixed: 'left',
      width: 100,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'name')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return (
          <div className="whitespace-normal">
            {t('フルネーム', {
              familyName: v.familyName,
              givenName: v.givenName,
            })}
          </div>
        )
      },
    },
    {
      title: t('生年月日'),
      dataIndex: ['attributes'],
      key: 'birthday',
      width: 138,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'birthday')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return (
          <div className="whitespace-nowrap">
            {v.yearBirth &&
              v.monthBirth &&
              v.dayBirth &&
              t('年月日', {
                year: v.yearBirth,
                month: t(`${v.monthBirth}月`),
                day: v.dayBirth,
              })}
          </div>
        )
      },
    },
    {
      title: t('総得点'),
      dataIndex: ['attributes', 'testResults'],
      key: 'points',
      className: 'text-center-f whitespace-nowrap',
      width: isUsingJp ? 70 : 128,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'points')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        const tr = v.find((d) => d.testYear === currentYear)

        return <div>{tr ? tr?.points : null}</div>
      },
    },
    {
      title: t('評価'),
      dataIndex: ['attributes', 'testResults'],
      key: 'rank',
      width: isUsingJp ? 60 : 128,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'rank')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        const tr = v.find((d) => d.testYear === currentYear)
        const status = checkIfIsAllTestResInputted(
          v,
          hasEnduranceRun,
          inputKeys,
          currentYear,
        )
        const showRanking = status || checkIfIsValidRank(tr, hasEnduranceRun)

        return <div>{showRanking ? tr?.rank : incorrectValue}</div>
      },
    },
    {
      title: t('身長'),
      dataIndex: [],
      key: 'height',
      width: 140,
      editable: true,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'height')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => {
        return EditableCell(v?._id, 'height', false)
      },
    },
    {
      title: t('体重'),
      dataIndex: [],
      key: 'weight',
      width: 140,
      editable: true,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'weight')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v: { _id: string } | undefined) => {
        // @ts-ignore
        return EditableCell(v?._id, 'weight', false)
      },
    },
    {
      title: t('BMI'),
      dataIndex: ['attributes', 'testResults'],
      key: 'bmi',
      width: 60,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'bmi')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v: { _id: string } | undefined) => {
        return renderTest(v, 'bmi')
      },
    },
    {
      title: t('握力'),
      dataIndex: [],
      key: 'grip',
      width: isUsingJp ? 826 : 698,
      editable: true,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'grip')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v: { _id: string } | undefined, d) => {
        const isDisabled =
          d?.attributes?.testResults?.[0]?.grip?.isNotMeasurable
        // @ts-ignore
        return EditableCell(v?._id, 'grip', isDisabled)
      },
    },
    {
      title: t('上体起こし'),
      dataIndex: [],
      key: 'sitUps',
      width: isUsingJp ? 140 : 176,
      editable: true,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'sitUps')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v: { _id: string } | undefined, d) => {
        const isDisabled =
          d?.attributes?.testResults?.[0]?.sitUps?.isNotMeasurable
        // @ts-ignore
        return EditableCell(v?._id, 'sitUps', isDisabled)
      },
    },
    {
      title: t('長座体前屈'),
      dataIndex: [],
      key: 'bending',
      width: 395,
      editable: true,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'bending')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v, d) => {
        const isDisabled =
          d?.attributes?.testResults?.[0]?.bending?.isNotMeasurable
        return EditableCell(v?._id, 'bending', isDisabled)
      },
    },
    {
      title: t('反復横跳び'),
      dataIndex: [],
      key: 'sideJump',
      width: 395,
      editable: true,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'sideJump')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v, d) => {
        const isDisabled =
          d?.attributes?.testResults?.[0]?.sideJump?.isNotMeasurable
        return EditableCell(v?._id, 'sideJump', isDisabled)
      },
    },
    {
      title: t('持久走'),
      dataIndex: [],
      key: 'enduranceRun',
      width: 300,
      editable: true,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'enduranceRun')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v, d) => {
        const isDisabled =
          d?.attributes?.testResults?.[0]?.enduranceRun?.isNotMeasurable
        return EditableCell(v?._id, 'enduranceRun', isDisabled)
      },
    },
    {
      title: t('20mシャトルラン'),
      dataIndex: [],
      key: 'shuttleRun',
      width: isUsingJp ? 152 : 168,
      editable: true,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'shuttleRun')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v, d) => {
        const isDisabled =
          d?.attributes?.testResults?.[0]?.shuttleRun?.isNotMeasurable
        return EditableCell(v?._id, 'shuttleRun', isDisabled)
      },
    },
    {
      title: t('50m走'),
      dataIndex: [],
      key: 'sprintRun',
      width: 140,
      editable: true,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'sprintRun')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v, d) => {
        const isDisabled =
          d?.attributes?.testResults?.[0]?.sprintRun?.isNotMeasurable
        return EditableCell(v?._id, 'sprintRun', isDisabled)
      },
    },
    {
      title: t('立ち幅跳び'),
      dataIndex: [],
      key: 'standingJump',
      width: 395,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'standingJump')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v, d) => {
        const isDisabled =
          d?.attributes?.testResults?.[0]?.standingJump?.isNotMeasurable
        return EditableCell(v?._id, 'standingJump', isDisabled)
      },
    },
    {
      title: t('ボール投げ'),
      dataIndex: [],
      key: 'handballThrow',
      width: 395,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'handballThrow')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v: { _id: string } | undefined, d) => {
        const isDisabled =
          d?.attributes?.testResults?.[0]?.handballThrow?.isNotMeasurable
        // @ts-ignore
        return EditableCell(v?._id, 'handballThrow', isDisabled)
      },
    },
  ]

  if (!hasEnduranceRun) {
    tableColumns = tableColumns.filter(
      (tableColumn) => tableColumn.key !== 'enduranceRun',
    )
  }

  const saveStudent = async (id: string, testKey: string) => {
    if (!school) {
      message.error(`${t('エラーが発生しました。')} [school is not found]`)
      console.error(
        'when call /alpha/v1/school/results, error: school is not found',
      )
      return
    }

    let dataSaveEdit = {}
    let checkValidate = false
    const dataEdit: (TStudent & { index: number })[] = []
    let testIndex = 0
    for (let i = 0; i < studentsRef.current.length; i++) {
      if (studentsRef.current[i]?._id === id) {
        testIndex = i
        dataEdit.push({ ...studentsRef.current[i], index: i })
      }
    }
    let canBeEdited = false
    // setReloading(true)
    dataEdit.forEach((test) => {
      const _id = test._id

      listInputEdit.forEach((input) => {
        if (input === testKey) {
          const listValidate = formEdit.getFieldError([`${input}_${_id}`])
          if (listValidate.length) {
            checkValidate = true
          }
        }
      })

      if (checkValidate) {
        return
      }

      const dataFormEdit = formEdit.getFieldsValue()
      const dataSave = {
        [testKey]: dataFormEdit[`${testKey}_${_id}`],
      }

      // check no change
      const oldData =
        studentsRef.current?.[test?.index]?.attributes?.testResults?.[0]
      if (oldData && !checkChangeTestKey(dataSave, oldData, testKey)) {
        return
      }

      const testKeyName = `${getTestNameByTestKey(testKey)}Test`
      dataSaveEdit = {
        ...(testKey !== 'height' && testKey !== 'weight'
          ? {
              [testKeyName]: {
                [getTestNameByTestKey(testKey)]: {
                  [testKey]: dataFormEdit[`${testKey}_${_id}`],
                },
              },
            }
          : {
              sizeTest: {
                [testKey]: dataFormEdit[`${testKey}_${_id}`],
              },
            }),
        studentId: _id,
      }

      canBeEdited = true

    })

    if (checkValidate || !canBeEdited) {
      return
    }

    if (Object.keys(dataSaveEdit).length > 0) {
      setShowDetailModal(false)
      submittedRef.current?.setAttribute('class', 'flex hidden')
      submittingRef.current?.setAttribute('class', 'flex')
      const {data: { data: newData }} = await Api.post('/alpha/v1/school/saveStudentTest', dataSaveEdit)
      // Recalc test result
      const schoolCategoryCode = school.attributes.schoolCategoryCode
      const age = ageFromSchool(
        schoolCategoryCode,
        studentsRef.current?.[testIndex].attributes.schoolGrade as number,
      )

      const gender = studentsRef.current?.[testIndex].attributes.gender
      const testResults = studentsRef.current[testIndex].attributes.testResults
      if (gender && testResults && testResults.length > 0) {
        testResults[0] = reCalcTestResult(
          newData,
          age,
          gender,
          hasEnduranceRun,
        ) as any
      }
      submittedRef.current?.setAttribute('class', 'flex')
      submittingRef.current?.setAttribute('class', 'flex hidden')
      changedCounterRef.current++
      const currentChangedCounter = changedCounterRef.current
      setTimeout(() => {
        if (currentChangedCounter === changedCounterRef.current) {
          setFilteredStudents([...studentsRef.current])
        }
      }, 3000)
    }
  }

  const editModal = (record) => {
    const defVal = record?.attributes?.testResults
    const tr = defVal.find((d) => d.testYear === currentYear)
    setIdModal(defVal?.studentId)
    form.setFieldsValue({
      weight: tr?.sizeTest?.weight,
      height: tr?.sizeTest?.height,
      gripNotMeasurable: tr?.grip?.isNotMeasurable,
      gripMemo: tr?.grip?.memo,
      sitUpsNotMeasurable: tr?.sitUps?.isNotMeasurable,
      sitUpsMemo: tr?.sitUps?.memo,
      bendingNotMeasurable: tr?.bending?.isNotMeasurable,
      bendingMemo: tr?.bending?.memo,
      sideJumpNotMeasurable: tr?.sideJump?.isNotMeasurable,
      sideJumpMemo: tr?.sideJump?.memo,
      enduranceRunNotMeasurable: tr?.enduranceRun?.isNotMeasurable,
      enduranceRunMemo: tr?.enduranceRun?.memo,
      sprintRunNotMeasurable: tr?.sprintRun?.isNotMeasurable,
      sprintRunMemo: tr?.sprintRun?.memo,
      standingJumpNotMeasurable: tr?.standingJump?.isNotMeasurable,
      standingJumpMemo: tr?.standingJump?.memo,
      handballThrowNotMeasurable: tr?.handballThrow?.isNotMeasurable,
      handballThrowMemo: tr?.handballThrow?.memo,
      gripRight1: tr?.grip?.gripRight1,
      gripRight2: tr?.grip?.gripRight2,
      gripLeft1: tr?.grip?.gripLeft1,
      gripLeft2: tr?.grip?.gripLeft2,
      sitUps: tr?.sitUps?.sitUps,
      bending1: tr?.bending?.bending1,
      bending2: tr?.bending?.bending2,
      sideJump1: tr?.sideJump?.sideJump1,
      sideJump2: tr?.sideJump?.sideJump2,
      shuttleRunCount: tr?.shuttleRun?.shuttleRunCount,
      shuttleRunNotMeasurable: tr?.shuttleRun?.isNotMeasurable,
      sprintRunSeconds: tr?.sprintRun?.sprintRunSeconds,
      standingJump1: tr?.standingJump?.standingJump1,
      standingJump2: tr?.standingJump?.standingJump2,
      handballThrow1: tr?.handballThrow?.handballThrow1,
      handballThrow2: tr?.handballThrow?.handballThrow2,
      enduranceRun: tr?.enduranceRun?.value,
      runningTime: tr?.enduranceRun?.runningTime,
      runningTimeSeconds: tr?.enduranceRun?.runningTimeSeconds,
    })
  }
  const screens = useBreakpoint()

  const measurableCheckboxWarning = (
    <>
      {t('「今年度測定なし」にチェックが入っているため編集ができません。')}
      <br />
      {t(
        '記録を入力する場合は詳細ボタンから「今年度測定なし」のチェックを外してください。',
      )}
    </>
  )

  const renderInput = (input: InputConfig) => {
    const tabTitleDefaultWidth = isUsingJp ? 'w-16' : 'w-32'
    const isDisabled = input.measurable && isNotMeasurable[input.key]

    return (
      <div key={input.key}>
        <Row>
          <div
            className={`h-5 ${
              input.tab_width ? input.tab_width : tabTitleDefaultWidth
            } bg-primary rounded-t-5px flex justify-center items-center text-white text-10px font-black`}
          >
            {input.tab}
          </div>
        </Row>
        <Row className="rounded-5px rounded-5px-without-tl bg-gray-150 p-1.5 mb-3">
          {input.items.map((item, index) => {
            return (
              <Col key={index} span={6}>
                <div className="flex relative">
                  {isDisabled ? (
                    <Popover content={measurableCheckboxWarning}>
                      <TestResInput
                        item={item}
                        input={input}
                        isNotMeasurable={isNotMeasurable}
                      />
                    </Popover>
                  ) : (
                    <TestResInput
                      item={item}
                      input={input}
                      isNotMeasurable={isNotMeasurable}
                    />
                  )}
                </div>
              </Col>
            )
          })}

          {input.measurable && (
            <Col key="measurable" span={input.items.length >= 4 ? 24 : 6}>
              <Form.Item
                className="mb-0-f"
                style={
                  input.items.find((item) => !item.label) ||
                  input.items.length === 4
                    ? {}
                    : { position: 'relative', top: '50%' }
                }
                name={`${input.key}NotMeasurable`}
                valuePropName="checked"
              >
                <Checkbox
                  className="text-10px font-normal"
                  onChange={(event) => {
                    const newValue = {
                      ...isNotMeasurable,
                    }
                    newValue[input.key] = event.target.checked
                    setIsNotMeasurable(newValue)
                    form.validateFields()
                  }}
                >
                  {t('今年度測定なし')}
                </Checkbox>
              </Form.Item>
            </Col>
          )}

          {input.memoable && (
            <Col key="memo" span={24}>
              {/* biome-ignore lint/a11y/noLabelWithoutControl: <explanation> */}
              <label className="text-10px">{t('メモ')}</label>
              <Form.Item className="mb-0-f" name={`${input.key}Memo`}>
                <Input size="small" />
              </Form.Item>
            </Col>
          )}
        </Row>
      </div>
    )
  }

  const onFinish = async (values) => {
    if (!school) {
      message.error(`${t('エラーが発生しました。')} [school is not found]`)
      console.error('when call school/result, error: school is not found')
      return
    }

    // selectedStudent is must be TStudent, because this function is called
    // when selectedStudent is not null.
    const updatedTestResOfStudent = convertValueForReqApi(
      values,
      isNotMeasurable,
      idModal,
      studentsRef.current,
      selectedStudent as TStudent,
    )

    try {
      setReloading(true)
      setLoading(true)
      const res = await Api.post(
        '/alpha/v1/school/result',
        updatedTestResOfStudent,
      )
      setShowDetailModal(false)
      form.resetFields()

      if (res.status === 500) {
        message.error(`${t('エラーが発生しました。')} [${res.data.error}]`)
        return
      }

      message.success(t('登録完了'))

      await getStudents({
        variables: {
          input: {
            schoolId: school._id,
            testYear: currentYear,
            schoolClass,
            schoolGrade,
          },
        },
      })
    } catch (err) {
      message.error(`${t('エラーが発生しました。')} [${err}]`)
      console.error('when call school/result, error:', err)
    } finally {
      setReloading(false)
      setLoading(false)
      setIdModal('')
    }
  }

  const totalCompletion = (filteredStudents ?? []).filter((result) =>
    checkIfIsAllTestResInputted(
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      result?.attributes?.testResults as Record<string, any>[] | undefined,
      hasEnduranceRun,
      inputKeys,
      currentYear,
    ),
  ).length

  const EditableCell = (
    studentId: string,
    dataIndex: TAllTestKeyHeightWeight,
    isDisabled: boolean,
  ) => {
    let form: null | JSX.Element = null

    switch (dataIndex) {
      case 'height':
        form = (
          <div className="flex items-center relative mr-4">
            {dataFormItem(dataIndex, studentId, isDisabled, dataIndex)}
            <div className="flex items-end absolute right-unit top-4">
              <span className="text-10px">cm</span>
            </div>
          </div>
        )
        break

      case 'sitUps':
        form = (
          <div className="flex items-center relative mr-4">
            {dataFormItem(dataIndex, studentId, isDisabled, dataIndex)}
            <div className="flex items-center -ml-2 text-10px">{t('回')}</div>
          </div>
        )
        break
      case 'weight':
        form = (
          <div className="flex items-center relative mr-4">
            {dataFormItem(dataIndex, studentId, isDisabled, dataIndex)}
            <div className="flex items-end absolute right-unit top-4">
              <span className="text-10px">kg</span>
            </div>
          </div>
        )
        break
      case 'grip': {
        const gripRight1 = dataFormItem(
          'gripRight1',
          studentId,
          isDisabled,
          dataIndex,
        )
        const gripRight2 = dataFormItem(
          'gripRight2',
          studentId,
          isDisabled,
          dataIndex,
        )
        const gripLeft1 = dataFormItem(
          'gripLeft1',
          studentId,
          isDisabled,
          dataIndex,
        )
        const gripLeft2 = dataFormItem(
          'gripLeft2',
          studentId,
          isDisabled,
          dataIndex,
        )

        form = isUsingJp ? (
          <>
            <div className="flex relative mr-4">
              <span className="mr-0 text-title">右 (1回目)</span>
              {gripRight1}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">kg</span>
              </div>
            </div>

            <div className="flex relative mr-4">
              <span className="mr-0 text-title">右 (2回目)</span>
              {gripRight2}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">kg</span>
              </div>
            </div>

            <div className="flex relative mr-4">
              <span className="mr-0 text-title">左 (1回目)</span>
              {gripLeft1}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">kg</span>
              </div>
            </div>

            <div className="flex relative mr-4">
              <span className="mr-0 text-title">左 (2回目)</span>
              {gripLeft2}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">kg</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex relative mr-4 text-xs">
              <span className="mr-0 text-title text-title-en">
                {t('右')}
                <br />
                {t('1回目_test')}
              </span>
              {gripRight1}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">kg</span>
              </div>
            </div>

            <div className="flex relative mr-4 text-xs">
              <span className="mr-0 text-title text-title-en">
                {t('右')}
                <br />
                {t('2回目_test')}
              </span>
              {gripRight2}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">kg</span>
              </div>
            </div>

            <div className="flex relative mr-4 text-xs">
              <span className="mr-0 text-title text-title-en">
                {t('左')}
                <br />
                {t('1回目_test')}
              </span>
              {gripLeft1}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">kg</span>
              </div>
            </div>

            <div className="flex relative mr-4 text-xs">
              <span className="mr-0 text-title text-title-en">
                {t('左')}
                <br />
                {t('2回目_test')}
              </span>
              {gripLeft2}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">kg</span>
              </div>
            </div>
          </>
        )
        break
      }

      case 'bending':
        form = (
          <>
            <div className="flex relative mr-4">
              <span className="mr-0 text-title">{t('1回目_test')}</span>
              {dataFormItem('bending1', studentId, isDisabled, dataIndex)}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">cm</span>
              </div>
            </div>
            <div className="flex relative mr-4">
              <span className="mr-0 text-title">{t('2回目_test')}</span>
              {dataFormItem('bending2', studentId, isDisabled, dataIndex)}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">cm</span>
              </div>
            </div>
          </>
        )
        break

      case 'sideJump':
        form = (
          <>
            <div className="flex relative mr-4">
              <span className="mr-0 text-title">{t('1回目_test')}</span>
              {dataFormItem('sideJump1', studentId, isDisabled, dataIndex)}
              <div className="flex items-center -ml-2 text-10px">{t('回')}</div>
            </div>

            <div className="flex relative mr-4">
              <span className="mr-0 text-title">{t('2回目_test')}</span>
              {dataFormItem('sideJump2', studentId, isDisabled, dataIndex)}
              <div className="flex items-center -ml-2 text-10px">{t('回')}</div>
            </div>
          </>
        )
        break

      case 'standingJump':
        form = (
          <>
            <div className="flex relative mr-4">
              <span className="mr-0 text-title">{t('1回目_test')}</span>
              {dataFormItem('standingJump1', studentId, isDisabled, dataIndex)}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">cm</span>
              </div>
            </div>
            <div className="flex relative mr-4">
              <span className="mr-0 text-title">{t('2回目_test')}</span>
              {dataFormItem('standingJump2', studentId, isDisabled, dataIndex)}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">cm</span>
              </div>
            </div>
          </>
        )
        break

      case 'handballThrow':
        form = (
          <>
            <div className="flex relative mr-4">
              <span className="mr-0 text-title">{t('1回目_test')}</span>
              {dataFormItem('handballThrow1', studentId, isDisabled, dataIndex)}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">m</span>
              </div>
            </div>

            <div className="flex relative mr-4">
              <span className="mr-0 text-title">{t('2回目_test')}</span>
              {dataFormItem('handballThrow2', studentId, isDisabled, dataIndex)}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">m</span>
              </div>
            </div>
          </>
        )
        break

      case 'enduranceRun':
        form = (
          <>
            <div className="flex items-center relative mr-4">
              {dataFormItem('runningTime', studentId, isDisabled, dataIndex)}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">{t('分')}</span>
              </div>
            </div>
            <div className="flex items-center relative mr-4">
              {dataFormItem(
                'runningTimeSeconds',
                studentId,
                isDisabled,
                dataIndex,
              )}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">{t('秒')}</span>
              </div>
            </div>
          </>
        )
        break

      case 'shuttleRun':
        form = (
          <div className="flex items-center relative mr-4">
            {dataFormItem('shuttleRunCount', studentId, isDisabled, dataIndex)}
            <div className="flex items-end -ml-2 text-10px">{t('回')}</div>
          </div>
        )
        break

      case 'sprintRun':
        form = (
          <div className="flex items-center relative mr-4">
            {dataFormItem('sprintRunSeconds', studentId, isDisabled, dataIndex)}
            <div
              className={`flex items-end absolute -right-2 top-4 ${
                isUsingJp ? 'right-unit' : '-right-2'
              }`}
            >
              <span className="text-10px">{t('秒')}</span>
            </div>
          </div>
        )
        break
    }

    return <div className="form-edit-table-test flex">{form}</div>
  }

  const dataFormItem = (
    dataIndex: string,
    studentId: string,
    isDisabled: boolean,
    mainTestIndex: TAllTestKeyHeightWeight,
  ) => {
    const rules = getInputRulesForTestRes(t)[dataIndex]

    const inputNode = (
      <Form.Item
        name={`${dataIndex}_${studentId}`}
        style={{ marginTop: 8, width: 120 }}
        className={isDisabled ? 'pointer-events-none' : ''}
        rules={rules}
        validateTrigger={['onSubmit', 'onBlur']}
      >
        <InputNumber
          disabled={isDisabled}
          onBlur={() => saveStudent(studentId, dataIndex)}
        />
      </Form.Item>
    )

    return isDisabled ? (
      <div
        onClick={() => {
          const keyId = allTestKeys.indexOf(mainTestIndex as TAllTestKey)
          if (keyId < 0 || 8 < keyId) {
            message.error(`${t('エラーが発生しました。')} key name is invalid!`)
            return
          }

          setMeasurableModalObj({ studentId, keyId })
        }}
      >
        {inputNode}
      </div>
    ) : (
      inputNode
    )
  }

  const [formEdit] = Form.useForm()
  const [listGrades, setListGrades] = useState<number[]>([])
  const [listClasses, setListClasses] = useState<number[]>([])

  const getFilterData = async () => {
    const apiUrl = `/alpha/v1/school/filterStudents?testYear=${currentYear}`

    const { data: dataFilter } = await Api.get(apiUrl)
    setListGrades(dataFilter?.data?.grades)

    setSchoolGrade(
      dataFilter?.data?.grades?.length ? dataFilter?.data?.grades[0] : 0,
    )
    setListClasses(dataFilter?.data?.classes)
    setSchoolClass(
      dataFilter?.data?.classes?.length ? dataFilter?.data?.classes[0] : 0,
    )

    setIsReadyToCallGetStudents(true)
  }

  useEffect(() => {
    if (filteredStudents?.length) {
      const formEditSave = setFormAll(filteredStudents, currentYear)
      formEdit.setFieldsValue(formEditSave)
    }
  }, [filteredStudents, formEdit])

  useEffect(() => {
    getFilterData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const weightHeightTabTitleDefaultWidth = isUsingJp ? 'w-12' : 'w-16'

  return (
    <Dashboard selectedMenu={3} navbar={t('体力テスト')}>
      <>
        <Row className="w-full justify-center">
          <Col className="mt-6" xxl={{ span: 20 }} lg={{ span: 22 }}>
            <div className="flex justify-between">
              {/* filter for table */}
              <div
                className={`space-x-2 pb-2 print:hidden mt-14 ${
                  isUsingJp
                    ? 'test-filter-group-btn-ja'
                    : 'test-filter-group-btn-en'
                }`}
              >
                <Select
                  className={`rounded-5px ${isUsingJp ? 'w-28' : 'w-34'}`}
                  value={schoolGrade}
                  onChange={onChangeGrade}
                  dropdownAlign={{
                    offset: [0, -2],
                  }}
                >
                  <Option key="grade-default" value={0}>
                    {t('全学年')}
                  </Option>
                  {listGrades.map((v) => (
                    <Option value={v} key={v}>
                      {t('個別学年', {
                        count: v,
                        ordinal: !isUsingJp,
                      })}
                    </Option>
                  ))}
                </Select>

                <Select
                  className={`rounded-5px ${isUsingJp ? 'w-28' : 'w-34'}`}
                  value={schoolClass}
                  onChange={onChangeClass}
                  dropdownAlign={{
                    offset: [0, -2],
                  }}
                >
                  <Option key="class-default" value={0}>
                    {t('全組')}
                  </Option>
                  {listClasses.map((_class) => {
                    return (
                      <Option key={`class-${_class}`} value={_class}>
                        {t('個別組', {
                          count: _class,
                          ordinal: !isUsingJp,
                        })}
                      </Option>
                    )
                  })}
                </Select>

                <Select
                  className={`rounded-5px ${isUsingJp ? 'w-28' : 'w-34'}`}
                  value={filterGender}
                  onChange={onChangeGender}
                  dropdownAlign={{
                    offset: [0, -2],
                  }}
                >
                  <Option key="gender-0" value="">
                    {t('全性別')}
                  </Option>
                  <Option key="gender-male" value="MALE">
                    {t('男性')}
                  </Option>
                  <Option key="gender-female" value="FEMALE">
                    {t('女性')}
                  </Option>
                </Select>
              </div>
            </div>

            {/* statistic at the top of table */}
            <div className="flex justify-between">
              <div className="mb-0 pb-0">
                <div className="text-10px font-black">
                  {t('生徒登録人数', {
                    student: isElementarySchool ? '児童' : '生徒',
                  })}
                  : {total}
                </div>
                <div className="text-10px font-black">
                  {t('入力済み人数')}: {totalCompletion}
                </div>
                <div className="text-10px font-black pb-2">
                  {t('未登録人数')}: {total - totalCompletion}
                </div>
              </div>
              <div className="mb-2 flex-col justify-items-end h-16">
                <div className="text-black p-2" style={{ backgroundColor: '#fef2cc' }}>※入力した値は自動で保存されます。</div>
                <div className="flex align-middle justify-center text-right text-black">
                  <div className="flex hidden" ref={submittedRef}>
                    <Space>
                      <CheckCircleOutlined style={{ color: 'black' }} />
                    </Space>
                    <div className="ml-1 pt-2">保存済み</div>
                  </div>
                  <div className="flex hidden" ref={submittingRef}>
                    <Space>
                      <SyncOutlined spin style={{ color: 'black' }} />
                    </Space>
                    <div className="ml-1 pt-2">保存中</div>
                  </div>
                </div>
              </div>
            </div>
            <Form form={formEdit} component={false}>
              <Table
                columns={tableColumns as ColumnsType<object>}
                tableLayout="fixed"
                dataSource={filteredStudents}
                loading={{
                  spinning: loadingStudent || reloading || countLoading,
                  indicator: (
                    <CircularProgressLoading percent={loadingPercent} />
                  ),
                }}
                rowKey="_id"
                size="small"
                style={{ minWidth: 940 }}
                scroll={{ x: 1500, y: '70vh' }}
                pagination={{
                  pageSize,
                  defaultPageSize,
                  position: ['bottomCenter'],
                  pageSizeOptions: [10, 20, 50, 100, 1000, 2000],
                  showSizeChanger: true,
                  total,
                  current: currentPage,
                  onChange: (page: number, pageSize: number) => {
                    setCurrentPage(page)
                    setPageSize(pageSize)
                  },
                }}
                bordered={true}
              />
            </Form>
          </Col>
        </Row>

        {selectedStudent && showDetailModal && (
          <Modal
            visible={showDetailModal}
            width={screens.xxl ? '70vw' : screens.lg ? '85vw' : '90vw'}
            onCancel={() => {
              setShowDetailModal(false)
              form.resetFields()
              setIdModal('')
            }}
            closeIcon={<CloseOutlined className="text-2xl color-blue-f" />}
            footer={null}
          >
            <Form form={form} layout="vertical" onFinish={onFinish}>
              <Row>
                <Col span={12} className="pr-2">
                  {getInputList()
                    .slice(0, 4)
                    .map((input) =>
                      renderInput(input as unknown as InputConfig),
                    )}
                </Col>

                <Col span={12} className="pl-2">
                  <Row className="space-x-2">
                    <Col span={6}>
                      <Row>
                        <div
                          className={`h-5 bg-primary rounded-t-5px flex justify-center items-center text-white text-10px font-black ${weightHeightTabTitleDefaultWidth}`}
                        >
                          {t('身長')}
                        </div>
                      </Row>

                      <Row className="rounded-5px rounded-5px-without-tl bg-gray-150 p-1.5 mb-3">
                        <Col span={6}>
                          <div className="flex relative">
                            <Form.Item
                              className="mb-0-f"
                              name="height"
                              rules={heightRules(t)}
                              validateTrigger={['onSubmit', 'onChange']}
                            >
                              <InputNumber
                                size="small"
                                type="number"
                                inputMode="decimal"
                                step="any"
                                parser={parseDisplayValueToValidateNumber}
                              />
                            </Form.Item>
                            <div className="flex items-end absolute left-24 top-4">
                              <span className="text-10px">cm</span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={6}>
                      <Row>
                        <div
                          className={`h-5 bg-primary rounded-t-5px flex justify-center items-center text-white text-10px font-black ${weightHeightTabTitleDefaultWidth}`}
                        >
                          {t('体重')}
                        </div>
                      </Row>
                      <Row className="rounded-5px rounded-5px-without-tl bg-gray-150 p-1.5 mb-3">
                        <Col span={6}>
                          <div className="flex relative">
                            <Form.Item
                              className="mb-0-f"
                              name="weight"
                              rules={weightRules(t)}
                              validateTrigger={['onSubmit', 'onChange']}
                            >
                              <InputNumber
                                size="small"
                                type="number"
                                inputMode="decimal"
                                step="any"
                                parser={parseDisplayValueToValidateNumber}
                              />
                            </Form.Item>
                            <div className="flex items-end absolute left-24 top-4">
                              <span className="text-10px">kg</span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {getInputList()
                    .slice(4)
                    .map((input) =>
                      renderInput(input as unknown as InputConfig),
                    )}
                </Col>
              </Row>
              <Row justify="center">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="w-60"
                    size="large"
                    loading={loading}
                  >
                    {t('登録')}
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </Modal>
        )}

        <MeasurableModal
          isModalOpen={measurableModalObj}
          setIsModalOpen={setMeasurableModalObj}
          handleOk={async () => {
            setMeasurableModalObj(false)

            if (!school) {
              message.error(
                `${t('エラーが発生しました。')} [school is not found]`,
              )
              return
            }

            if (!measurableModalObj || typeof measurableModalObj !== 'object') {
              message.error(
                `${t('エラーが発生しました。')} [studentId is not found]`,
              )
              return
            }

            const apiUrl = `/alpha/v1/school/student/${measurableModalObj.studentId}/result/measurable`

            setReloading(true)

            try {
              await Api.post(apiUrl, {
                isNotMeasurable: false,
                keyId: measurableModalObj.keyId,
              })

              // reload the table
              await getStudents({
                variables: {
                  input: {
                    schoolId: school._id,
                    testYear: currentYear,
                    schoolClass,
                    schoolGrade,
                  },
                },
              })
            } catch (err) {
              message.error(
                `${t('エラーが発生しました。')} [${JSON.stringify(
                  (err as Error).message,
                )}]`,
              )

              console.error(`when call ${apiUrl} - error:`, err)
            } finally {
              setReloading(false)
            }
          }}
        />
      </>
    </Dashboard>
  )
}

export default TestInputPage
