import './VideoModal.css'

import { CloseOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import Video from '../cards/Video'

interface VideoModalProps {
  video: {
    url: string
    thumb: string
  } | null
  onClose: () => void
}

export const VideoModal = ({ video, onClose }: VideoModalProps) => {
  if (!video) return null

  const ratio = 0.67

  return (
    <Modal
      visible={!!video}
      onCancel={onClose}
      footer={null}
      centered
      width="auto"
      closeIcon={
        <CloseOutlined
          className="absolute -top-8 -right-1 text-2xl opacity-80 font-bold hover:opacity-100"
          style={{ color: '#FFF' }}
        />
      }
      className="video-modal"
    >
      <Video
        src={video.url}
        size={{
          width: window.screen.width * ratio,
          height: window.screen.height * ratio,
        }}
        thumb={video.thumb}
        className="rounded-none"
      />
    </Modal>
  )
}
