import { useMenuStore } from '~/context/menu'
import DropdownMenu from './DropdownMenu'
import RightDropdown from './RightDropdown'

export default function TopBar() {
  const { titleView } = useMenuStore()

  return (
    <div className="flex justify-center text-white bg-primary items-center fixed w-full h-12 z-50 print:hidden">
      <div className="absolute left-0">
        <DropdownMenu />
      </div>

      <div className="text-base font-black">{titleView}</div>

      <div className="absolute right-4 flex font-bold text-lg">
        <RightDropdown />
      </div>
    </div>
  )
}
