import './SettingModal.css'

import { Checkbox, Col, Modal, Row } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import Api from '~/utils/api'
import { countConfDefaultValues as defaultValue } from '~/utils/test-result'

interface SettingsModalProps {
  isVisible: boolean
  onOk: () => Promise<unknown>
  onCancel: () => void
  isElementarySchool: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

type TTestCountConf = null | number[]

const SettingsModal: React.FC<SettingsModalProps> = ({
  isVisible,
  onOk,
  onCancel,
  isElementarySchool,
  setIsLoading,
}) => {
  const { t } = useTranslation()
  const [countConf, setCountConf] = useState<TTestCountConf>(null)
  const fetchedRes = useRef<undefined | { data: TTestCountConf }>(undefined)

  const fetchCountConf = async () => {
    setIsLoading(true)
    try {
      const res = await Api.get<TTestCountConf>(
        '/alpha/v1/school/test-count-conf',
      )

      setCountConf(res.data)
      fetchedRes.current = { data: res.data }
    } catch (err) {
      console.error(err)
    }
    setIsLoading(false)
  }

  const putCountConf = async (countConf: TTestCountConf) => {
    const requested = countConf ?? defaultValue

    try {
      await Api.put('/alpha/v1/school/test-count-conf', {
        testCount: requested,
      })

      fetchedRes.current = { data: requested }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!isVisible) return

    if (fetchedRes.current) {
      // when modal is opened, set checkedList
      setCountConf(fetchedRes.current.data)
      return
    }

    // when 1st time open modal, set checkedList
    fetchCountConf()
  }, [isVisible])

  return (
    <Modal
      visible={isVisible}
      onOk={async () => {
        setIsLoading(true)
        await putCountConf(countConf)
        await onOk()
        setIsLoading(false)
      }}
      onCancel={onCancel}
      width={720}
      okText={t('登録')}
    >
      <div className="my-6 text-black">
        <p>{t('入力が必要ないものはチェックを外してください。')}</p>
        <p>
          {t(
            'チェックが入っているすべての項目の入力が完了している生徒の数が「入力済」とカウントされます。',
          )}
        </p>

        {!isElementarySchool && (
          <>
            <span>※{t('持久走/20mシャトルランの項目に関して')}</span>
            <br />
            <span className="ml-8">
              {t(
                'チェックが入っている場合：最低でもどちらかを入力する必要がある',
              )}
            </span>
            <br />
            <span className="ml-8">
              {t('チェックが外れている場合：どちらにも入力する必要がない')}
            </span>
          </>
        )}
      </div>

      {/* <div className="mt-4">
        <Checkbox>
          <span>{t('身長')}</span>
        </Checkbox>
      </div>
      <div className="mt-2">
        <Checkbox>
          <span>{t('体重')}</span>
        </Checkbox>
      </div> */}

      <div className="test-checkbox-group">
        <Checkbox.Group
          // options={options}
          defaultValue={defaultValue}
          value={countConf ?? defaultValue}
          style={{ borderRadius: '9px' }}
          className="my-4"
          onChange={(checkedValues: CheckboxValueType[]) => {
            setCountConf(checkedValues as number[])
          }}
        >
          <Row>
            <Col span={4} className="bg-ccd4f8 rounded-md px-2 py-1 m-2">
              <Checkbox value={1}>{t('身長')}</Checkbox>
            </Col>
            <Col span={4} className="bg-ccd4f8 rounded-md px-2 py-1 m-2">
              <Checkbox value={2}>{t('体重')}</Checkbox>
            </Col>
            <Col span={4} className="bg-ccd4f8 rounded-md px-2 py-1 m-2">
              <Checkbox value={3}>{t('握力')}</Checkbox>
            </Col>
            <Col span={4} className="bg-ccd4f8 rounded-md px-2 py-1 m-2">
              <Checkbox value={4}>{t('上体起こし')}</Checkbox>
            </Col>
            <Col span={4} className="bg-ccd4f8 rounded-md px-2 py-1 m-2">
              <Checkbox value={5}>{t('長座体前屈')}</Checkbox>
            </Col>

            <Col span={4} className="bg-ccd4f8 rounded-md px-2 py-1 m-2">
              <Checkbox value={6}>{t('反復横跳び')}</Checkbox>
            </Col>

            <Col span={5.5} className="bg-ccd4f8 rounded-md px-2 py-1 m-2">
              <Checkbox value={7}>{`${
                isElementarySchool ? '' : `${t('持久走')} / `
              }${t('20mシャトルラン')}`}</Checkbox>
            </Col>

            <Col span={3.8} className="bg-ccd4f8 rounded-md px-2 py-1 m-2">
              <Checkbox value={8}>{t('50m走')}</Checkbox>
            </Col>

            <Col span={4} className="bg-ccd4f8 rounded-md px-2 py-1 m-2">
              <Checkbox value={9}>{t('立ち幅とび')}</Checkbox>
            </Col>

            <Col span={4} className="bg-ccd4f8 rounded-md px-2 py-1 m-2">
              <Checkbox value={10}>{t('ボール投げ')}</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </div>
    </Modal>
  )
}

export default SettingsModal
