import { Button, Col, Row, Select, Space, Table, message } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { set_cptable, utils, writeFile } from 'xlsx'
import * as cp_table from 'xlsx/dist/cpexcel.full.mjs'

import {
  QuestionnaireV3Result,
  SchoolReportTestByGrade,
  TestResult,
  fetchSchoolClasses,
} from '@alpha/core'

import { ColumnType } from 'antd/lib/table/interface'
import { useAlphaStore } from '~/context'
import {
  useSchoolGradesApiQuery,
  useSchoolSurveysApiQuery,
} from '~/hooks/hooks'
import { useLoadingPercent } from '~/hooks/useLoadingPercent'
import type { Survey } from '~/types/api/survey'
import Api from '~/utils/api'
import { currentYear, pageSize as defaultPageSize } from '~/utils/constants'
import isJapanese from '~/utils/isJapanese'
import {
  getCurrentPrefectureQuestions,
  getIsOptionalQuestions,
  getSortedQuestionnairesIdArr,
} from '~/utils/questions'
import { allQuestions, getExtraQuestion } from '~/utils/questions/all-questions'
import type { StudentQuestionExtra } from '~/utils/types'
import { Dashboard } from '../layout/Dashboard'
import CircularProgressLoading from '../shared/CircularProgressLoading'
import { incorrectValue } from '../shared/IncorrectValue'
import { checkIfCompletedSurvey } from './question-result/utils/status'

set_cptable(cp_table)

// WARNING: this translation is using for Xlsx title generation

const { Option } = Select

const sortTestResults = (a, b, key: string): 0 | 1 | -1 => {
  let element1: string | number
  let element2: string | number
  if (key.startsWith('q')) {
    const a_qV3 = a.attributes?.testResults?.[0]?.questionnaireV3
    const b_qV3 = b.attributes?.testResults?.[0]?.questionnaireV3
    element1 = a_qV3?.[key]
    element2 = b_qV3?.[key]
  } else if (key === 'name') {
    element1 = `${a?.attributes?.familyName ?? ''}${
      a?.attributes?.givenName ?? ''
    }`
    element2 = `${b?.attributes?.familyName ?? ''}${
      b?.attributes?.givenName ?? ''
    }`
  } else {
    element1 = a?.[key]
    element2 = b?.[key]
  }

  if (element1 == null && element2 == null) return 1
  if (element1 == null) return -1
  if (element2 == null) return 1
  return (
    typeof element1 === 'number'
      ? element1 - (element2 as number)
      : element1.localeCompare(element2 as string)
  ) as 0 | 1 | -1
}

let isOptionalQuestions: boolean[]

/**
 * /questionnaire_result
 */
const QuestionnaireResultPage = () => {
  const { school } = useAlphaStore()
  const [schoolGrade, setSchoolGrade] = useState(0)
  const [schoolClass, setSchoolClass] = useState(0)
  const [schoolClasses, setSchoolClasses] = useState<number[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [total, setTotal] = useState(0)

  const { t, i18n } = useTranslation()

  const isUsingJp = isJapanese(i18n)

  const { data: grades, loading: gradesLoading } = useSchoolGradesApiQuery()

  const { data: surveys, loading: surveysLoading } = useSchoolSurveysApiQuery({
    testYear: currentYear,
    schoolGrade,
    schoolClass,
    limit: pageSize,
    offset: (currentPage - 1) * pageSize,
  })

  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'

  const prefectureCode = school?.attributes?.prefectureCode

  const totalQuestionnaires: number[] = prefectureCode
    ? getSortedQuestionnairesIdArr(
        prefectureCode,
        school?._id,
        isElementarySchool,
      )
    : []

  const [downloading, setDownloading] = useState(false)

  useEffect(() => {
    if (school) {
      fetchSchoolClasses(school._id, currentYear).then((res) => {
        const schoolClasses = res.schoolClasses

        if (schoolClasses.length) {
          setSchoolClasses(schoolClasses)
        }
      })
    }
  }, [school])

  useEffect(() => {
    setTotal(surveys?.total ?? 0)
  }, [surveys?.total])

  const downloadXlsx = async () => {
    if (!school?._id) {
      message.error(`${t('エラーが発生しました。')} [school is not available!]`)
      return
    }

    setDownloading(true)

    const apiUrl = `/alpha/v1/school/survey?schoolId=${school._id}&testYear=${currentYear}`

    try {
      const { data: surveys }: { data: { data: Survey[] } } =
        await Api.get(apiUrl)

      const xlsxData = surveys.data.map((student) => {
        const attributes = student.attributes
        const testResult = attributes.testResults?.[0]
        const questionnaireV3 = testResult?.questionnaireV3
        return {
          [t('学年')]: attributes.schoolGrade,
          [t('組')]: attributes.schoolClass,
          [t('番')]: attributes.schoolAttendanceNumber,
          ...Object.fromEntries(
            listQuestion.map((qKey, index) => {
              const qIndex = `q${index + 1}`
              let dataAnswer: number | string | undefined =
                questionnaireV3?.[qIndex]

              if (qKey === 45 && dataAnswer && questionExtra[dataAnswer]) {
                dataAnswer = `${dataAnswer}.${questionExtra[dataAnswer].name}`
              }

              return [qIndex, dataAnswer]
            }),
          ),
        }
      })

      const workbook = utils.book_new()
      const worksheet = utils.json_to_sheet(xlsxData)
      utils.book_append_sheet(workbook, worksheet, '中高用')

      writeFile(workbook, 'questionnaire_result.xlsx', {
        bookType: 'xlsx',
      })
    } finally {
      setDownloading(false)
    }
  }

  const [listQuestion, setListQuestion] = useState<number[]>([])
  const [questionExtra, setQuestionExtra] = useState<StudentQuestionExtra>({})

  useEffect(() => {
    if (!school || prefectureCode === undefined) return

    const questionList = getCurrentPrefectureQuestions(
      prefectureCode,
      school._id,
      isElementarySchool,
    )

    setListQuestion(questionList)

    // reset the list
    isOptionalQuestions = getIsOptionalQuestions(questionList)

    for (const allQuestionsItem of allQuestions) {
      const extraQuestion = getExtraQuestion(
        school?.attributes?.schoolName,
        allQuestionsItem,
      )
      if (allQuestionsItem.id === 45 && extraQuestion) {
        setQuestionExtra(extraQuestion)
      }
    }
  }, [school, isElementarySchool, prefectureCode])

  const editableColumns = [
    {
      title: t('提出状況'),
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap',
      width: isUsingJp ? 96 : 180,
      key: 'status',
      sorter: (a: Survey | undefined, b: Survey | undefined) => {
        const isCompletedSurvey1 = checkIfCompletedSurvey(
          totalQuestionnaires,
          a?.attributes?.testResults,
          isOptionalQuestions,
        )

        const isCompletedSurvey2 = checkIfCompletedSurvey(
          totalQuestionnaires,
          b?.attributes?.testResults,
          isOptionalQuestions,
        )

        return isCompletedSurvey1 === isCompletedSurvey2
          ? 0
          : isCompletedSurvey1
            ? 1
            : -1
      },

      sortDirections: ['descend', 'ascend'],

      render: (
        studentTestResults:
          | SchoolReportTestByGrade[]
          | TestResult[]
          | undefined,
      ) => {
        const isCompletedSurvey = checkIfCompletedSurvey(
          totalQuestionnaires,
          studentTestResults,
          isOptionalQuestions,
        )

        return (
          <div
            className="flex justify-center items-center"
            style={{ height: 22, width: 60 }}
          >
            <div
              className="flex justify-center items-center h-4 w-4 ml-5 rounded-3px text-xxs font-bold text-white"
              style={{
                backgroundColor: isCompletedSurvey ? '#00944D' : '#CA4141',
              }}
            >
              {isCompletedSurvey ? '済' : '未'}
            </div>
          </div>
        )
      },
    },
    {
      title: t('学年'),
      key: 'schoolGrade',
      dataIndex: ['attributes', 'schoolGrade'],
      className: 'text-center-f whitespace-nowrap',
      width: isUsingJp ? 64 : 96,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolGrade')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('クラス'),
      key: 'schoolClass',
      dataIndex: ['attributes', 'schoolClass'],
      className: 'text-center-f whitespace-nowrap',
      width: 72,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolClass')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('出席番号'),
      key: 'schoolAttendanceNumber',
      dataIndex: ['attributes', 'schoolAttendanceNumber'],
      className: 'text-center-f whitespace-nowrap',
      width: 96,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolAttendanceNumber')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('氏名'),
      dataIndex: ['attributes'],
      className: 'text-center-f whitespace-nowrap',
      width: 118,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'name')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v: { familyName: string; givenName: string }, _: unknown) => (
        <div>
          {t('フルネーム', {
            familyName: v.familyName,
            givenName: v.givenName,
          })}
        </div>
      ),
    },
    ...listQuestion.map((questionKey: number, index) => {
      const qk = `q${index + 1}`
      const isSelectionQuestion = questionKey === 45

      return {
        title: `Q${index + 1}`,
        dataIndex: qk,
        key: qk,
        width: isSelectionQuestion ? 212 : 46,
        className: 'text-center-f whitespace-nowrap bg-white',
        sorter: (a, b) => {
          return sortTestResults(a, b, qk)
        },
        sortDirections: ['descend', 'ascend'],
        render: (_v, r) => {
          if (
            !r.attributes?.testResults ||
            r.attributes?.testResults.length === 0
          )
            return incorrectValue

          const questionnaireV3: QuestionnaireV3Result | undefined =
            r.attributes.testResults[0]?.questionnaireV3

          const answer = questionnaireV3?.[qk]
          if (answer == null) return incorrectValue

          if (isSelectionQuestion) {
            // selection question
            if (!answer) {
              return incorrectValue
            }

            return (
              <div>
                {answer}.{questionExtra[answer].name}
              </div>
            )
          }

          return <div>{answer}</div>
        },
      }
    }),
  ].map((col) => {
    if (!(col as { editable?: boolean }).editable) {
      return col
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: (col as { editable?: boolean }).editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  const loadingPercent = useLoadingPercent()

  return (
    <Dashboard selectedMenu={4} navbar={t('アンケート一覧')}>
      <div className="mx-6">
        <Col className="mt-16">
          <div className="pb-8 print:hidden">
            <Row className="w-full space-x-2">
              <Col>
                <Space size="middle">
                  <Select
                    value={schoolGrade}
                    onChange={setSchoolGrade}
                    className={`rounded-5px ${isUsingJp ? 'w-30' : 'w-36'}`}
                    dropdownAlign={{
                      offset: [0, -2],
                    }}
                  >
                    <Option key={'grade-default'} value={0}>
                      {t('全学年')}
                    </Option>

                    {grades?.data?.map((v) => (
                      <Option value={v.grade} key={v.grade}>
                        {t('個別学年', {
                          count: v.grade,
                          ordinal: !isUsingJp,
                        })}
                      </Option>
                    ))}
                  </Select>

                  <Select
                    value={schoolClass}
                    onChange={setSchoolClass}
                    className={`rounded-5px ${isUsingJp ? 'w-30' : 'w-36'}`}
                    dropdownAlign={{
                      offset: [0, -2],
                    }}
                  >
                    <Option key={'class-default'} value={0}>
                      {t('全組')}
                    </Option>
                    {schoolClasses.map((_class) => {
                      return (
                        <Option key={`${_class}`} value={_class}>
                          {t('個別組', {
                            count: _class,
                            ordinal: !isUsingJp,
                          })}
                        </Option>
                      )
                    })}
                  </Select>
                </Space>
              </Col>

              <Col flex="auto">
                <Button
                  type="primary"
                  className="float-right w-50"
                  onClick={downloadXlsx}
                  disabled={downloading}
                >
                  {downloading ? t('ダウンロード中...') : t('一斉ダウンロード')}
                </Button>
              </Col>
            </Row>
          </div>

          <div>
            {t('生徒数', {
              student: isElementarySchool ? '児童' : '生徒',
            })}
            : {total}
          </div>

          <Table
            columns={editableColumns as ColumnType<Survey>[]}
            rowKey="schoolAttendanceNumber"
            dataSource={surveys?.data}
            loading={{
              spinning: gradesLoading || surveysLoading,
              indicator: <CircularProgressLoading percent={loadingPercent} />,
            }}
            size="small"
            rowClassName="font-bold text-black"
            bordered={true}
            scroll={{ x: '100vw', y: 600 }}
            pagination={{
              pageSize,
              defaultPageSize: 50,
              total,
              current: currentPage,
              pageSizeOptions: [10, 20, 50, 100, 1000, 2000],
              onChange: (page: number, pageSize: number) => {
                setCurrentPage(page)
                setPageSize(pageSize)
              },
            }}
          />
        </Col>
      </div>
    </Dashboard>
  )
}

export default QuestionnaireResultPage
