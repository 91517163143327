import { message } from 'antd'
import { TFunction } from 'i18next'
import { WorkBook, read, set_cptable, utils, writeFile } from 'xlsx'
import * as cp_table from 'xlsx/dist/cpexcel.full.mjs'
import { SchoolInvitationCode } from '~/alpha/core'
// const XLSX = require('xlsx-style');
// import XLSXStyle from 'xlsx-style'
import { getFullStudentInvitationCode } from './code'

set_cptable(cp_table)

/**
 *
 * @param grade 学年
 * @param schoolClass 組
 * @param students
 * @param schoolInvitationCode
 */
export const downloadInvitationCodeExcelFile = (
  grade: number,
  schoolClass: number,
  students: SchoolInvitationCode['students'] | undefined,
  schoolInvitationCode: string,
  t: TFunction<'translation', undefined>,
) => {
  const workbook = utils.book_new()

  const columnTitles = [
    t('学年'),
    t('組'),
    t('出席番号'),
    t('姓'),
    t('名'),
    t('サインインコード'),
  ]

  const studentRows: (string | number)[][] = [columnTitles]

  // @ts-ignore
  for (const student of students) {
    const row = [
      grade,
      schoolClass,
      // @ts-ignore
      student.attendanceNumber,
      // @ts-ignore
      student.familyName,
      // @ts-ignore
      student.givenName,
      getFullStudentInvitationCode(
        schoolInvitationCode,
        // @ts-ignore
        student.attendanceNumber,
        // @ts-ignore
        student.studentInvitationCode,
      ),
    ]

    // @ts-ignore
    studentRows.push(row)
  }

  const worksheet = utils.json_to_sheet(studentRows, { skipHeader: true })

  // utils.sheet_add_aoa(worksheet, [columnTitles, [22, 11, 12]])
  // utils.sheet_add_json(worksheet, [], { origin: 'A2' })
  utils.book_append_sheet(workbook, worksheet, t('生徒登録'))

  const fileName = `ALPHA${t('サインインコード一覧')}${t('（')}${grade}${t(
    'grade年',
  )}${schoolClass}${t('組')}${t('）')}.xlsx`

  writeFile(workbook, fileName, {
    bookType: 'xlsx',
  })
}

/**
 * @param t
 * @param fileName - The name of the file in the public folder without the extension
 * @returns
 */
export const readWorkbookFromPublic = async (
  t: TFunction<'translation', undefined>,
  fileName: string,
): Promise<undefined | WorkBook> => {
  let response: Response
  try {
    // Fetch the XLSX file from the public folder
    response = await fetch(`/${fileName}.xlsx`)
    const blob = await response.blob()
    // Read the file as an ArrayBuffer
    const arrayBuffer = await blob.arrayBuffer()

    // Read the workbook from the ArrayBuffer
    return read(arrayBuffer, { type: 'array' })
  } catch (error) {
    message.error(
      `${t('エラーが発生しました。')} Error downloading the template file: [${
        (error as Error).message
      }]`,
    )

    console.error('Error downloading the template file:', error)
    return undefined
  }
}

/**
 *
 * @param t
 * @param fileName - The name of the file in the public folder without the extension
 * @returns
 */
export const updateWorkbookFromPublic = async (
  t: TFunction<'translation', undefined>,
  fileName: string,
) => {
  // 'res' is the name of the file in the public folder without the extension
  // Step 1: Read the workbook from the public folder
  const workbook: WorkBook | undefined = await readWorkbookFromPublic(
    t,
    fileName,
  )

  if (workbook === undefined) {
    return
  }

  // Step 2: Access the desired sheet by name or index
  const sheetName = workbook.SheetNames[2] // For example, first sheet
  const worksheet = workbook.Sheets[sheetName]

  // Step 3: Modify the sheet (e.g., update cell A1)
  worksheet.B4 = { t: 'n', v: 25 }
  worksheet.C4 = { t: 'n', v: 25 }
  worksheet.D4 = { t: 'n', v: 25 }
  worksheet.E4 = { t: 'n', v: 25 }

  // Step 4: Write the updated workbook back to a file
  writeFile(workbook, 'updated_file.xlsx')
}
