import { Form, Select, message } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Api from '~/utils/api'
import { currentYear } from '~/utils/constants'

function getListData(start: number, end: number, state = true) {
  const list: { value: string; label: string }[] = []
  if (state) {
    for (let i = start; i <= end; i++) {
      list.push({ value: i.toString(), label: i.toString() })
    }
  } else {
    for (let i = end; i >= start; i--) {
      list.push({ value: i.toString(), label: i.toString() })
    }
  }
  return list
}

export const DateForm = ({
  dataBirth,
}: {
  dataBirth: {
    _id: string
    givenName: string
    givenNameHiragana: string | null
    familyName: string
    familyNameHiragana: string | null
    gender: 'FEMALE' | 'MALE'
    yearBirth: string | number | null
    monthBirth: number | null
    dayBirth: number | null
    schoolGrade: number
    schoolClass: number
    schoolAttendanceNumber: number
  }
}) => {
  const listYear = getListData(1970, currentYear, false)
  const listMonth = getListData(1, 12)
  const [listDay, setListDay] = useState(getListData(1, 31))
  const { t } = useTranslation()

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(dataBirth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataBirth])

  const handleChange = async () => {
    const data = form.getFieldsValue()

    if (
      !data ||
      data.yearBirth == null ||
      data.yearBirth === '' ||
      data.monthBirth == null ||
      data.monthBirth === '' ||
      data.dayBirth == null ||
      data.dayBirth === ''
    ) {
      return
    }

    if (data.yearBirth && data.monthBirth) {
      const date = new Date(data.yearBirth, data.monthBirth, 0).getDate()
      setListDay(getListData(1, date))

      if (data.dayBirth) {
        if (data.dayBirth > date) {
          // invalid day
          form.setFieldsValue({
            yearBirth: data.yearBirth,
            monthBirth: data.monthBirth,
            dayBirth: '',
          })
          return
        }
      }
    }

    if (!dataBirth) {
      return
    }

    const submitData = form.getFieldsValue()

    if (!submitData) {
      message.error(t('データが空です'))
      return
    }

    const { yearBirth, monthBirth, dayBirth } = submitData

    const apiUrl = `/alpha/v1/school/student/${dataBirth._id}/birth`

    try {
      await Api.post(apiUrl, {
        yearBirth: Number(yearBirth),
        monthBirth: Number(monthBirth),
        dayBirth: Number(dayBirth),
      })

      message.success(t('編集完了'))
    } catch (err) {
      console.error(`${apiUrl} API calling error:`, err)
    }
  }
  return (
    <Form form={form} layout="vertical" hideRequiredMark={true}>
      <div className="flex align-center items-center my-2">
        <Form.Item name="yearBirth">
          <Select
            style={{ width: 120 }}
            onChange={handleChange}
            options={listYear}
          />
        </Form.Item>
        <span className="mx-2 textDate flex items-center">年</span>
        <Form.Item name="monthBirth">
          <Select
            style={{ width: 80 }}
            onChange={handleChange}
            options={listMonth}
          />
        </Form.Item>
        <span className="mx-2 textDate flex items-center">月</span>
        <Form.Item name="dayBirth">
          <Select
            style={{ width: 80 }}
            onChange={handleChange}
            options={listDay}
          />
        </Form.Item>
        <span className="mx-2 textDate flex items-center">日</span>
      </div>
    </Form>
  )
}
