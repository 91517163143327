import { useApolloClient } from '@apollo/client'
import { Button, message } from 'antd'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useMenuStore } from '~/context/menu'
import { useSchoolMeApiQuery } from '~/hooks/useSchoolMeApiQuery'
import { callLogoutApiForSchool } from '~/services/api/logout'
import Card from '../cards/Card'

interface DashboardProps {
  navbar: JSX.Element | string
  children: JSX.Element | string
  selectedMenu: number | undefined
}

/**
 * Main layout for pages.
 */
export const Dashboard = ({
  navbar,
  children,
  selectedMenu = 1,
}: DashboardProps) => {
  const history = useHistory()
  const { t } = useTranslation()
  const client = useApolloClient()

  const {
    data: teacherData,
    loading: teacherLoading,
    error: teacherError,
  } = useSchoolMeApiQuery()

  const { setMenu } = useMenuStore()

  useEffect(() => {
    setMenu(selectedMenu, navbar)
  }, [selectedMenu, navbar])

  const logoutUserWhenNotFoundTeacher = useCallback(async () => {
    try {
      await callLogoutApiForSchool()
      await client.clearStore()

      setTimeout(() => {
        window.location.reload()
      }, 300)
    } catch (err) {
      message.error(`${t('エラーが発生しました。')} [${JSON.stringify(err)}]`)
      console.error('when logout and clearStore, error:', err)
    }
  }, [client, t])

  useEffect(() => {
    if (teacherLoading || !teacherData) return
    if (!teacherData.school) {
      logoutUserWhenNotFoundTeacher()
      return
    }
  }, [teacherLoading, teacherData, logoutUserWhenNotFoundTeacher])

  if (!teacherLoading && teacherError?.response?.status === 401) {
    // loading is done, school response is null and API unauthorized error
    // then force teacher logout!
    // WARNING: Only at 04/01 each year, user will be forced to logout!
    // So carefully when use this component to force teacher logout!
    return (
      <div className="w-screen h-screen flex items-center justify-center bg-gray-400">
        <Card>
          <div className="flex flex-col items-center justify-center space-y-4">
            <Button
              type="primary"
              onClick={() => {
                history.push('/code')
              }}
            >
              {t('招待コードを入力')}
            </Button>
            <Button
              type="primary"
              onClick={async () => {
                try {
                  await callLogoutApiForSchool()
                  await client.clearStore()

                  setTimeout(() => {
                    window.location.reload()
                  }, 2)
                } catch (err) {
                  message.error(
                    `${t('エラーが発生しました。')} [${JSON.stringify(err)}]`,
                  )
                  console.error('when logout and clearStore, error:', err)
                }
              }}
            >
              {t('ログアウト')}
            </Button>
          </div>
        </Card>
      </div>
    )
  }

  return (
    <div className="h-screen flex flex-col bg-gray-50 overflow-x-scroll">
      <div className="mt-12">{children}</div>
    </div>
  )
}
