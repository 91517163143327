import { Form, InputNumber } from 'antd'
import { parseDisplayValueToValidateNumber } from '~/utils/validations'
import type { InputConfig, InputConfigItem } from '../constants'

interface IProps {
  item: InputConfigItem
  input: InputConfig
  isNotMeasurable: Record<string, boolean>
}

const TestResInput = ({ item, input, isNotMeasurable }: IProps) => {
  const isDisabled = input.measurable && isNotMeasurable[input.key]

  return (
    <>
      <Form.Item
        className="mb-0-f"
        name={item.name}
        label={
          item.label ? <span className="text-10px">{item.label}</span> : null
        }
        rules={isDisabled === true ? [] : item.rules}
        validateTrigger={isDisabled === true ? [] : ['onSubmit', 'onChange']}
      >
        <InputNumber
          size="small"
          disabled={isNotMeasurable[input.key]}
          parser={parseDisplayValueToValidateNumber}
          step="any"
        />
      </Form.Item>

      {item.suffix && (
        <div
          className={`flex items-end absolute left-24 ${
            item.label ? 'top-10' : 'top-4'
          }`}
        >
          <span className="text-10px">{item.suffix}</span>
        </div>
      )}
    </>
  )
}

export default TestResInput
